.custom-header-bg {
  position: relative;
  overflow: visible;
  background-image: linear-gradient(-100deg, #249396 0%, #249396 0.9%, #187c82 15.1%, #0c646d 27.5%, #085b66 30.9%, #070707 100%);
  
  @media (min-width: $bp-large) {
    border-bottom-right-radius: 200px;
    max-height: 600px;
    margin-bottom: 125px;
  }

}

.resources-hero {
  color: #FFFFFF;

  span {
    font-size: 28px;
    font-weight: 600;
    color:#249396;
  }

  h1 {
    font-size: 65px;
    font-weight: 600;

    @media (max-width: $bp-medium) {
      font-size: 42px;
    }
  }
}

.intro-offers {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  width: 100%;
  max-width: 1030px;
  margin: 0 auto;

  @media (max-width: $bp-large) {
    display: none;
  }
}

.modules-box {
  position: relative;
  padding: 75px 100px 65px 50px;
  background-color: #B77B0B;

  width: 100%;
  max-width: 50%;

  box-shadow: 0px 25px 35px 0px rgba(183, 123, 11,0.3); /* Only bottom shadow */

  h2 {
    font-size: 30px;
    font-weight: 600;
    margin:0;
    padding:0;
  }
  p {
    font-size:16px;
    font-weight: 400;
  }

  a.arrow-btn {
    background-color: #fff;
  }

}

.offer-box {
  position: relative;
  padding: 50px 100px 50px 50px;
  background-color: #FFFFFF;
  border:1px solid #249396;

  width: 100%;

  box-shadow: 0px 25px 35px 0px rgba(0, 0, 0,0.2);

  h2 {
    font-size: 30px;
    font-weight: 600;
    color:#249396;
    margin:0;
    padding:0;
    max-width: 80%;
  }

  p {
    font-size:16px;
    font-weight: 400;
    color: #070707;
    max-width: 80%;
  }

  a.arrow-btn {
    background-color: #B77B0B;
  }
}

a.arrow-btn {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -19px;
}

.wide-box {
  max-width: 1230px;
  padding-top: 95px;
  margin-bottom: 120px;
}

.title-area {
  text-align: center;
  margin-bottom: 32px;

  h3 {
    font-size: 50px;
    font-weight: 600;
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 1.2;
  }
  span {
    font-size: 18px;
    font-weight: 400;
  }
}

.you-may-like {
  position: relative;
  background-image: linear-gradient(to top right, #249396 0%, #249396 7.9%, #187c82 20.1%, #0c646d 36.5%, #085b66 46.5%, #070707 100%);
  padding-top: 130px;

  h2 {
    padding-top: 0;
    font-size: 50px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
  }
}

.cards {
  margin-bottom: 130px;
}

.cards .grid-third {
  background-color: #FFFFFF;
}

.cards .text-small {
  font-size: 15px;
  font-weight: 400;
}

.get-it-free h2 {
  color:#249396;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  padding-top: 30px;
}

.get-it-free a.btn {
  font-size: 16px;
  font-weight: 600;
}

.get-it-free-box {
  padding-top: 66px;
  padding-left: 150px;
  padding-right: 60px;
  padding-bottom: 70px;
  border: 1px solid #0A545F;
}

.get-it-free-box img {
  display: none;
  @media (min-width: $bp-large) {
    display: block;
  }
}

/* Overriding the default styles */
#resources .text-medium {
  font-size: 24px;
  font-weight: 600;
}

#resources .text-regular-plus {
  font-size: 19px;
  font-weight: 600;
}

#resources a.link-accent {
  font-size: 16px;
  font-weight: 600;
}

#resources a.link-accent > svg {
  width: 16px;
  height: 16px;
  position: relative;
  top:2px;
  margin-left: 5px;
}

#resources .grid-half img {
  width: 100%;
  height: auto;
}

.lesson-text {
  font-size: 18px;
  font-weight: 700;

}
/**
 * @Section Images
 * Image styling
 */

/**
 * Adds a 50% border radius, turning square images into a circle.
 */
.img-circle {
	border-radius: 50%;
}


/**
 * Image alignment
 */

.img-left {
	display: block;
	float: left;
	margin-right: 1em;
}


/**
 * Have an inline image cover its container
 */

.img-cover {
	object-fit: cover;
}
/**
 * @section Grid
 * Structure and layout
 */

/**
 * Base wrapper class
 */
.container {
	@extend .clearfix;
	margin-left: auto;
	margin-right: auto;
	max-width: var(--container-max-width);
	width: var(--container-width);
}

.container-wide {
	max-width: var(--container-wide-max-width);
}

.container-xwide {
	max-width: var(--container-xwide-max-width);
}

.container-medium {
	max-width: var(--container-medium-max-width);
}

.container-narrow {
	max-width: var(--container-narrow-max-width);
}

.container-narrow-plus {
	max-width: var(--container-narrow-plus-max-width);
}

.container-tiny {
    max-width: var(--container-tiny-max-width);
}

@media (min-width: $bp-large) {
	.container-left {
		margin-left: 0;
	}
}

@media (max-width: $bp-xlarge) {
	.container .container {
		width: 100%;
	}
}


/**
 * Grids
 */

@media (min-width: $bp-large) {

	.row {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr auto;
		column-gap: 0.75em;
	}

	.row-center {
		align-items: center;
	}

	.row-gap-medium {
		column-gap: var(--spacing);
	}

	.row-gap-large {
		column-gap: 2.1875em;
	}

	.row-gap-xlarge {
		column-gap: 3em;
	}

	.row-gap-xxlarge {
		column-gap: 4em;
	}

	.row-no-gap {
		gap: 0;
	}

	.row-auto {
		grid-template-columns: repeat(auto-fit, minmax(min(100%, 15em), 1fr));
	}

	.grid-fourth {
		grid-column: auto / span 3;
	}

	.grid-third {
		grid-column: auto / span 4;
	}

	.grid-two-fifths {
		grid-column: auto / span 5;
	}

	.grid-two-thirds {
		grid-column: auto / span 8;
	}

	.grid-half {
		grid-column: auto / span 6;
	}

	.grid-three-fifths {
		grid-column: auto / span 7;
	}

	.grid-three-fourths {
		grid-column: auto / span 9;
	}

	.grid-full {
		grid-column: auto / span 12;
	}

	[class*="grid-start-"] {
		grid-row-start: 1;
	}

	.grid-start-first {
		grid-column-start: 1;
	}

	.grid-start-fifth {
		grid-column-start: 2;
	}

	.grid-start-fourth-minus {
		grid-column-start: 3;
	}

	.grid-start-fourth {
		grid-column-start: 3;
	}

	.grid-start-third {
		grid-column-start: 4;
	}

	.grid-start-half-minus {
		grid-column-start: 5;
	}

	.grid-start-half {
		grid-column-start: 7;
	}

	.grid-start-two-fifths {
		grid-column-start: 8;
	}

	.grid-start-two-thirds {
		grid-column-start: 9;
	}

	.grid-start-three-fourths {
		grid-column-start: 10;
	}

}

/**
 * Grids
 */

@media (min-width: $bp-medium) {

	.row-small {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr auto;
		column-gap: 0.75em;

		.grid-third {
			grid-column: auto / span 4;
		}

		.grid-half {
			grid-column: auto / span 6;
		}
	}

}

// @media (min-width: $bp-large-mid) {
// 	.row-large-viewports {
// 		display: grid;
// 		grid-template-columns: repeat(12, 1fr);
// 		grid-template-rows: 1fr auto;
// 	}
// }
/**
 * @section Forms
 * Styling for form elements.
 */

form,
fieldset {
	margin-bottom: var(--spacing);
}

fieldset {
	border: 0;
	padding: 0;
}

legend,
label {
	display: block;
	font-size: 0.9375em;
	// margin: 0 0 0.3125em;
	margin: 0;
	padding: 0;
}

/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
	color: var(--color-gray-darker); /* 1 */
	font: inherit; /* 2 */
	margin: 0; /* 3 */
	padding: 0.3125em;
}

input,
textarea,
select {
	background-color: var(--color-gray-lighter);
	border: 1px solid var(--color-gray-light);
	// box-shadow: inset 0.375em 0.375em 0.375em var(--color-gray-medium), inset -0.375em -0.375em 0.375em var(--color-white);
	color: var(--color-black);
	display: block;
	line-height: 1.5;
	// margin-bottom: 1.1875em;
	margin-bottom: 0.5em;
	width: 100%;
    padding-left: 1em;
    padding-right: 1em;

	@media (min-width: $bp-medium) {
		line-height: 1.5625;
	}
}

input {
	border-radius: 2em;

    &::placeholder {
        color: var(--color-primary-darkest);
    }
}

form.no-radius {
    input {
        border-radius: 0;
    }
}

form button:not(.btn-search),
form .btn:not(.btn-search) {
	margin-bottom: 1.1875em;
}

textarea {
	height: 8em;
	overflow: auto;
	resize: vertical;
    &::placeholder {
        color: var(--color-primary-darkest);
    }
}

[type="image"],
[type="checkbox"],
[type="radio"] {
	cursor: pointer;
	display: inline-block;
	height: auto;
	margin-bottom: 0.3125em;
	padding: 0;
	width: auto;
}

input:focus,
textarea:focus {
	border-color: rgba(82, 168, 236, 0.8);
}


/**
 * Inline inputs
 */
.input-inline {
	display: inline-block;
	vertical-align: middle;
	width: auto;
}


/**
 * Condensed inputs
 */
.input-condensed {
	padding: 1px 0.3125em;
	font-size: 0.9375em;
}

/**
 * Large inputs
 */
.input-large {
	padding: 1.5em 3em;
}


/**
 * Search
 */


/**
 * Create rounded search bar
 */
.input-search {
	width: 85%;
	padding-left: 2em;
	padding-right: 2em;
	border-radius: 3em;
	// transition: width 300ms ease-in;

	@media (min-width: $bp-medium) {
		padding-right: 14em;
	}
}


/**
 * Special styling for search icon as button
 */

@media (min-width: $bp-medium) {
	.btn-search {
		display: inline;
		// color: var(--color-gray-dark);
		// border: none;
		// background: none;
		margin-left: -12.35em;
		margin-bottom: 0.5em;

		// &:hover {
		// 	color: var(--color-gray-darker);
		// }
	}
}

/* Login */
.login-item {
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;

    input {
        position: relative;
        background-color: var(--color-form-bg);
        border: 1px solid var(--color-form-bg);
        height: 4em;
        padding-left: 4em;
        border-radius: 5em;

        &::placeholder {
            color: var(--color-primary-darkest);
            text-transform: uppercase;
        }
    }

    i {
        speak: never;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 24px;
        height: 4em;


        position: absolute;
        left: 42px;
        top: 0;
        z-index: 10;

        pointer-events: none;

        &.icon-password {
            background-image: url('data:image/svg+xml;utf8,<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.9 438.53"><defs><style>.cls-1{stroke-width:0px;}</style></defs><path class="cls-1" d="m320.9,227.26c-5.33-5.33-11.8-7.99-19.42-7.99H91.36v-91.36c0-20.18,7.14-37.4,21.42-51.68,14.28-14.27,31.5-21.41,51.68-21.41s37.4,7.14,51.67,21.41c14.28,14.28,21.42,31.5,21.42,51.68,0,4.95,1.81,9.23,5.42,12.85,3.62,3.62,7.9,5.43,12.85,5.43h18.28c4.95,0,9.23-1.81,12.85-5.43,3.61-3.62,5.42-7.9,5.42-12.85,0-35.22-12.52-65.33-37.54-90.36C229.78,12.51,199.66,0,164.45,0s-65.33,12.51-90.37,37.54c-25.03,25.03-37.54,55.15-37.54,90.36v91.36h-9.14c-7.61,0-14.08,2.67-19.41,7.99s-7.99,11.8-7.99,19.41v164.45c0,7.62,2.67,14.09,7.99,19.42,5.33,5.33,11.8,7.99,19.41,7.99h274.08c7.62,0,14.09-2.67,19.42-7.99,5.33-5.33,7.99-11.8,7.99-19.42v-164.45c0-7.61-2.66-14.08-7.99-19.41Z"/></svg>');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            fill: var(--color-primary-darkest);
        }

        &.icon-user {
            background-image: url('data:image/svg+xml;utf8,<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.16 512"><defs><style>.cls-1{stroke-width:0px;}</style></defs><path class="cls-1" d="m210.35,246.63c33.88,0,63.22-12.15,87.2-36.13,23.97-23.97,36.12-53.3,36.12-87.19s-12.15-63.21-36.13-87.19C273.57,12.15,244.23,0,210.35,0s-63.22,12.15-87.19,36.12-36.13,53.31-36.13,87.19,12.16,63.22,36.13,87.2c23.98,23.97,53.31,36.12,87.19,36.12Z"/><path class="cls-1" d="m426.13,393.7c-.69-9.98-2.09-20.86-4.15-32.35-2.08-11.58-4.75-22.52-7.96-32.53-3.31-10.34-7.81-20.55-13.37-30.34-5.77-10.16-12.55-19-20.16-26.28-7.96-7.61-17.7-13.73-28.96-18.2-11.23-4.44-23.67-6.69-36.98-6.69-5.23,0-10.28,2.14-20.04,8.5-6.01,3.92-13.04,8.45-20.88,13.46-6.71,4.27-15.79,8.28-27.02,11.9-10.95,3.54-22.07,5.34-33.04,5.34s-22.09-1.8-33.05-5.34c-11.21-3.62-20.3-7.62-27-11.9-7.77-4.96-14.8-9.5-20.9-13.47-9.75-6.36-14.81-8.5-20.04-8.5-13.31,0-25.75,2.25-36.97,6.7-11.26,4.46-21,10.58-28.97,18.2-7.61,7.28-14.39,16.12-20.16,26.27-5.56,9.79-10.06,19.99-13.37,30.34-3.2,10-5.88,20.95-7.95,32.52-2.06,11.48-3.46,22.36-4.15,32.36-.68,9.8-1.02,19.96-1.02,30.23,0,26.73,8.5,48.36,25.25,64.32,16.55,15.75,38.44,23.73,65.07,23.73h246.53c26.62,0,48.51-7.98,65.06-23.73,16.76-15.95,25.25-37.59,25.25-64.32,0-10.32-.35-20.49-1.04-30.24Z"/></svg>');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            fill: var(--color-primary-darkest);
        }
    }
}

.remember {
    padding-left: 48px;
    color: var(--color-primary);

    [type=checkbox] {
        border-color: var(--color-primary);
    }
}
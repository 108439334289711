/**
 * @section Callouts
 * UI for callout elements
 */

 @media (min-width: $bp-large) {
 	.callout-right-large {
 		display: block;
 		float: right;
 		margin-left: 1em;
 		margin-bottom: var(--spacing);
 		width: 45%;
 	}
 }
/**
 * @section Tables
 * Styling for tables
 */

table {
	border-collapse: collapse;
	border-spacing: 0;
	margin-bottom: var(--spacing);
	max-width: 100%;
	width: 100%;
}

th,
td {
	text-align: left;
	padding: 0.5em;
}

th {
	border-bottom: 0.125em solid var(--color-gray-light);
	font-weight: bold;
	vertical-align: bottom;
}

td {
	border-top: 1px solid var(--color-gray-light);
	vertical-align: top;
}

/**
 * Adds zebra striping
 */
.table-striped tbody tr:nth-child(odd) {
	background-color: var(--color-gray-lighter);
}


/**
 * Reduces padding on condensed tables
 */
.table-condensed th,
.table-condensed td, {
	padding: 0.25em;
}
/**
 * @section Hero images
 */

.hero-home {
	background: var(--color-primary-darkest);
	background: linear-gradient(45deg, var(--color-primary-darkest) 0%, var(--color-primary) 85%, var(--color-primary) 100%);
	padding-bottom: 7.5em;

	@media (min-width: $bp-large) {
		background-color: var(--color-white);
		background-image: url(../img/tony-podium.png);
		background-size: cover;
		background-position: bottom right;
	}
}

.hero-home-highlights {
	@media (max-width: $bp-large) {
		margin-top: -20em;
	}
}

.hero-home-video {
	margin-top: -6em;
}

.hero-home-opportunity {
	@media (min-width: $bp-large) {
		background-image: url(../img/stock-stairs.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom right;
	}
}

.hero-home-knowing {
	background-image: linear-gradient(10deg, rgba(17, 17, 17, 0.6) 0%, rgba(17, 17, 17, .6) 25%, rgba(73, 145, 149, 0.6) 60%, rgba(73, 145, 149, 0.6) 100%), url(../img/stock-woman-1.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top right;
}

.hero-home-win-win-win {
	@media (min-width: $bp-large) {
		background-image: url(../img/stock-cityscape.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		padding-bottom: 22em;
	}
}

.hero-home-introducing-masterclass {
	@media (min-width: $bp-large) {
		padding-bottom: 24em;
	}
}

.hero-home-fraction {
	@media (min-width: $bp-large) {
		margin-top: -26em;
	}

	@media (max-width: $bp-large) {
		& .container-xwide {
			padding-left: 2em;
			padding-right: 2em;
			max-width: 100%;
			width: 100%;
		}
	}
}

.hero-home-bonus {
	@media (min-width: $bp-large) {
		background-image: url(../img/stock-man-1.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right;
	}

	@media (max-width: $bp-large) {
		padding-left: 0;

		& > .padding-xlarge {
			padding: 2em;
		}
	}
}

.hero-home-about-tony {
	@media (min-width: $bp-large) {
		background-image: url(../img/tony-chair.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom center;
	}
}

.hero-home-people-like-you {
    position: relative;
	@media (min-width: $bp-large) {
		padding-bottom: 12em;
	}

	@media (max-width: $bp-large) {
		padding-top: 0;
	}
}

.logo-overlay {
    position: relative;
    @media (min-width: $bp-large) {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url('../img/ustla-watermark.svg');
            background-size: 80%; // or auto
            background-repeat: no-repeat;
            background-position: center;
            opacity: 5%;  
            z-index: 0;
            pointer-events: none;
        }
    }
}

.hero-home-perks {
	@media (min-width: $bp-large) {
		margin-top: -10em;
	}
}

.hero-footer-guide {
	@media (min-width: $bp-large) {
		margin-bottom: -12em;
		position: relative;
		z-index: 999;
	}
}

.hero-footer-bg {
	@media (min-width: $bp-large) {
		padding-top: 16em;
	}
}

.hero-blog-index-header {
	padding-bottom: 10em;
}

.hero-blog-index-list {
	margin-top: -8em;
}

.hero-tony-chair {
	@media (min-width: $bp-large) {
		background-image: url(../img/tony-chair-alt.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom left;
	}
}

.hero-footer {
	background: var(--color-primary-darkest);
	background: linear-gradient(45deg, var(--color-primary-darkest) 0%, var(--color-primary) 85%, var(--color-primary) 100%);
	padding-bottom: 7.5em;

	@media (min-width: $bp-large) {
		background-color: var(--color-white);
		background-image: url(../img/tony-podium-footer.png);
		background-size: cover;
		background-position: bottom right;
	}

    &.with-banner {
        padding-bottom: 0;
    }
}

.hero-why-choose-us-header {
	padding-bottom: 14em;
}

.hero-why-choose-us-video {
	margin-top: -12em;
}

.hero-404 {
	background: var(--color-primary-darkest);
	background: linear-gradient(45deg, var(--color-primary-darkest) 0%, var(--color-primary) 85%, var(--color-primary) 100%);

	// @media (min-width: $bp-large) {
		background-color: var(--color-white);
		background-image: url(../img/404.png);
		background-size: cover;
		background-position: bottom center;
	// }
}
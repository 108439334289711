/**
 * @section Accordion Elements
 */


/**
 * Style the headers to look differently
 */
[data-accordion] {
	@extend .clearfix;
	font-size: 1.1875em;
	font-weight: normal;
	padding-top: 0;
	margin-bottom: 0.5em;
}


/**
 * Style the accordion buttons to look like headers
 */
[data-accordion] > button {
	background: transparent;
	border: none;
	display: flex;
	font: inherit;
	margin: 0;
	padding: 0;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

/**
 * Show expand/collapse icons
 */
[data-accordion] > button[aria-expanded]::after {
	color: var(--color-accent);
	font-size: 2em;
	font-weight: 400;
	float: right;
}

[data-accordion] > button[aria-expanded="true"]::after {
	content: " –";
}

[data-accordion] > button[aria-expanded="false"]::after {
	content: " +";
}
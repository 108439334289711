/**
 * @section Overrides
 * Nudge and tweak alignment, spacing, and visibility.
 */


/**
 * Text sizes
 */

.text-small {
	font-size: 0.9375em;
}

.text-xsmall {
	font-size: 0.8125em;
}

@media (max-width: $bp-medium) {
	.text-xsmall-mobile {
		font-size: 0.8125em;
	}
}

.text-regular {
	font-size: 1em;
}

.text-regular-plus {
	font-size: 1.1875em;
}

.text-size-plus {
	font-size: 1.0625em;
}

.text-medium {
	font-size: 1.3125em;
	line-height: 1.4;

	@media (min-width: $bp-medium) {
		font-size: 1.5em;
	}
}

// .text-medium-plus {
// 	font-size: 1.1875em;
// 	line-height: 1.4;

// 	@media (min-width: $bp-medium) {
// 		font-size: 1.5em;
// 	}
// }

.text-large {
	font-size: 1.3125em;
	line-height: 1.4;

	@media (min-width: $bp-medium) {
		font-size: 1.7em;
	}
}

.text-xlarge {
	@extend .text-large;
	font-size: 1.7em;

	@media (min-width: $bp-medium) {
		font-size: 2em;
	}
}

// .text-xxlarge {
// 	font-size: 1.7em;
// 	line-height: 1.2;

// 	@media (min-width: $bp-large) {
// 		font-size: 2em;
// 	}
// }

// .text-xxxlarge {
// 	font-size: 2em;
// 	line-height: 1;

// 	// @media (min-width: $bp-medium) {
// 	// 	font-size: 2.2em;
// 	// }

// 	@media (min-width: $bp-large) {
// 		font-size: 2.5em;
// 	}
// }

.text-compact {
	line-height: 1.4;
}

.text-more-compact {
	line-height: 1.2;
}



/**
 * Text colors
 */

.text-muted {
	color: var(--color-gray-dark);
}

.text-primary {
	color: var(--color-primary-light);
}

.text-primary-dark {
	color: var(--color-primary);
}

.text-secondary {
	color: var(--color-secondary);
}

.text-white {
	color: white;
}


/**
 * Text transforms
 */

.text-normal {
	font-weight: normal;
	font-style: normal;
}

.text-extra-bold {
	font-weight: 900;
}

.text-uppercase {
	text-transform: uppercase;
}


/**
 * Text alignment
 */

.text-center {
	text-align: center;
}

@media (max-width: $bp-large) {
	.text-center-small {
		text-align: center;
	}
}

.text-right {
	text-align: right;
}

@media (min-width: $bp-large) {
	.text-right-large {
		text-align: right;
	}
}

.text-left {
	text-align: left;
}

/**
 * Images
 */

.img-full-width {
	width: 100%;
}

.img-circle {
	border-radius: 50%;
}

.img-transparent {
	opacity: 0.5;
}

.full-bleed {
	margin-left: calc(50% - 50vw);
	min-width: 100vw;
	width: 100vw;
}

.full-bleed-img {
	@extend .full-bleed;
	img {
		display: block;
		margin: 0 auto;
		max-width: 47em;
	}
}

// Homepage Additions
.case-study-img {
    width: 100%;
    height: auto;
    max-width: 444px;
}





/**
 * Border & Shadow
 */

.border {
	border: 1px solid var(--color-primary-light);
}

.border-dark {
	border-color: var(--color-primary-dark);
}

.border-top-right {
	border-top-right-radius: 2.5em;
}

.border-top-right-large {
	border-top-right-radius: 6em;
}

.border-top-left {
	border-top-left-radius: 2.5em;
}

.border-top-left-large {
	border-top-left-radius: 12em;
}

.border-bottom-right {
	border-bottom-right-radius: 2.5em;
}

.border-bottom-right-large {
	border-bottom-right-radius: 6em;
}

@media (min-width: $bp-large) {
	.border-bottom-right-xlarge {
		border-bottom-right-radius: 12em;
	}
}

.border-bottom-left {
	border-bottom-left-radius: 2.5em;
}

.border-bottom-left-large {
	border-bottom-left-radius: 6em;
}

.shadow {
	box-shadow: 0.1875em 0.25em 0.5em 0 var(--color-gray-medium);
}


/**
 * Borders & Shadows
 */

.border-bottom-primary {
	border-bottom: 0.6875em var(--color-primary-light) solid;
}

.drop-shadow {
	box-shadow: 0.375em 0.375em 0.375em var(--color-gray-light), -0.375em -0.375em 0.375em var(--color-white);
}

/**
 * Floats
 */

.float-left {
	float: left;
}

.float-center {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: $bp-large) {
	.float-center-two-thirds {
		margin-left: auto;
		margin-right: auto;
		width: 67%;
	}

	.float-center-three-fourths {
		margin-left: auto;
		margin-right: auto;
		width: 75%;
	}
}

.float-right {
	float: right;
}


/**
 * Margins
 */

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.margin-top {
	margin-top: var(--spacing);
}

.margin-bottom {
	margin-bottom: var(--spacing);
}

.margin-top-small {
	margin-top: 0.5em;
}

.margin-bottom-small {
	margin-bottom: 0.5em;
}

.margin-bottom-xsmall {
	margin-bottom: 0.25em;
}

.margin-bottom-medium {
	margin-bottom: 1em;
}

.margin-bottom-large {
	margin-bottom: 2em;
}

.margin-bottom-xlarge {
	margin-bottom: 4em;
}


/**
 * Padding
 */

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.padding {
	padding: 1.3125em;
}

.padding-small {
	padding: 0.25em;
}

.padding-medium {
	padding: 1em;
}

.padding-large {
	padding: 2em;
}

.padding-xlarge {
	padding: 2.5em;

	@media (min-width: $bp-small) {
		padding: 4em;
	}
}

.padding-top {
	padding-top: var(--spacing);
}

.padding-top-small {
	padding-top: 0.5em;
}


.padding-top-xsmall {
	padding-top: 0.25em;
}

.padding-top-large {
	padding-top: 3em;
}

.padding-top-xlarge {
	padding-top: 4em;
}

.padding-top-gigantic {
	padding-top: 20em;
}

.footer-banner-padding {
    padding-bottom: 4em;
}

@media (min-width: $bp-large) {
	.padding-top-xlarge-desktop {
		padding-top: 4em;
	}

	.padding-bottom-xlarge-desktop {
		padding-bottom: 4em;
	}

    .footer-banner-padding {
        padding-top: 10em;
        padding-bottom: 8em;
    }

	.padding-top-xxlarge-desktop {
		padding-top: 10em;
	}
}

.padding-bottom {
	padding-bottom: var(--spacing);
}

.padding-bottom-small {
	padding-bottom: 0.5em;
}

.padding-bottom-large {
	padding-bottom: 3em;
}

.padding-bottom-xlarge {
	padding-bottom: 4em;
}


.padding-left {
	padding-left: 4em;
}

@media (min-width: $bp-large) {
	.padding-left-large {
		padding-left: 4em;
	}
}

.padding-left-medium {
	padding-left: 2em;
}

.padding-right {
	padding-right: 4em;
}

.padding-left-large {
	padding-left: 1em;
}

.padding-right-large {
	padding-right: 1em;
}

@media (min-width: $bp-large) {
	.padding-left-large {
		padding-left: 4em;
	}

	.padding-right-large {
		padding-right: 4em;
	}
}

@media (max-width: $bp-large) {
	.padding-left-small {
		padding-left: 2em;
	}

	.padding-right-small {
		padding-right: 2em;
	}
}




/**
 * Visibility
 */

@media (max-width: $bp-large) {
	.hide-small {
		display: none;
	}
}

@media (min-width: $bp-large) {
	.hide-large {
		display: none;
	}
}



/**
 * Accessibility
 */

/*
 * Hide only visually, but have it available for screen readers:
 * @link https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap; /* 1 */
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard
 * @link https://www.drupal.org/node/897638
 */
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
}


/**
 * Remove all animations and transitions for people that prefer not to see them
 */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}


/**
 * Clear any floats
 */
.clearfix:before,
.clearfix:after {
	display: table;
	content: " ";
}

.clearfix:after {
	clear: both;
}


/********
* Mike's changes to the code based on personal opinion
* Hyper targeted styles
*********/

.hero-footer .text-xsmall.text-center.text-muted,
.reg-footer .text-xsmall.text-center.text-muted {
    font-size: 12px;
}

.hero-footer .grid-half.text-right-large .list-inline,
.reg-footer .grid-half.text-right-large .list-inline {
    justify-content: center;
    @media (min-width: $bp-large) {
        justify-content: flex-end;
    }
}

.contain-hack {
    display: inline-block;
    text-align: center;
    a {
        display: block;
    }
    
    span {
        display: block;
        clear: both;
    }
}

.banner-max {
    max-width: 730px; // making it pixel perfect Grid is too wide
}

.login-socials .btn-circle {
    height: 48px;
    width: 48px;
    padding: 0;
    line-height: 51px;
    font-size: 18px;
}

@media (min-width: 56em) {
    .bg-hq {
        background-image: url("../img/bg-hq.png");
        background-position: right;
        background-size: 75%;
        background-repeat: no-repeat;
        color: var(--color-white);
    }
    .bg-hq a {
        color: var(--color-white);
    }
}
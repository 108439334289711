// @section Configuration Settings
// Adjust colors, font stacks, breakpoints and sizing.
//
// Typographic Scale
// (For math purposes. Actual font sizes in ems.)
// 1px, 4px, 5px, 8px, 9px, 11px, 12px, 13px, 15px, 16px, 19px, 21px, 24px, 28px, 32px, 48px, 64px, 80px, 96px
// line height: 1.5em on small screens, 1.5625em on big screens


// Breakpoints
$bp-xsmall: 20em;
$bp-small: 30em;
$bp-medium: 38em;
$bp-large: 80em;
$bp-xlarge: 90em;

/**
 * Configs
 */
:root {

    /* Colors */
    --color-primary: #255964;
    --color-primary-dark: #1e323e;
    --color-primary-darkest: #111111;
    --color-primary-middle: #096069;
    --color-primary-light: #239395;
    --color-primary-lightest: #67a3a6;
    --color-secondary: #f8d749;
    --color-secondary-dark: #D1B63D;
    --color-accent: #b77b0b;
    --color-accent-dark: #946C34;
    --color-code: #dd1144;
    --color-highlight: #fbf9c9;

    --color-check: #57c913;
    --color-gray: #f4f3f0;

    --color-hr-line: #e6e6e4;

    --color-black: #272727;
    --color-white: #ffffff;
    --color-gray-dark: #808080;
    --color-gray-darker: #555555;
    --color-gray-medium: #d6d3c9;
    --color-gray-light: #e5e5e5;
    --color-gray-lighter: #f7f7f7;

    /* Fonts */
    --font-primary: "Poppins", sans-serif;
    --font-monospace: Menlo, Monaco, "Courier New", monospace;

    /* Form Colors */
    --color-form-bg: #f4f3ef;

    /* Sizes */
    --font-size: 112.5%;
    --spacing: 1.5625em;
    --container-width: 92%;
    --container-max-width: 62em;
    --container-medium-max-width: 70em;
    --container-wide-max-width: 77em;
    --container-xwide-max-width: 82em;
    --container-narrow-max-width: 44em;
    --container-narrow-plus-max-width: 56em;
    --container-tiny-max-width: 32em;
    --body-max-width: 107em;

    @media (max-width: $bp-large) {
        --font-size: 106.25%
    }

    @media (max-width: $bp-medium) {
        --font-size: 100%;
    }

}
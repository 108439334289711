/**
 * @section Why Us
 */

 // Color Overrides

.alt-p-color {
    color: #17333F;
 }

// Base Styles
.hero-why-choose-us-header {
    padding-bottom: 4em;
}

.message-from {
    position: relative;
    overflow: visible;
    @media (min-width: $bp-large) {
    min-height: 900px;}
}

.bg-hack {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (min-width: $bp-large) {
        height: calc(100% - 175px);
    }
}

// Large screen adjustments
@media (min-width: $bp-large) {
    #why-us .watermark-custom {
        display: block;
        position: absolute;
        top: -130px;
        left: -345px;
        width: 1000px;
        height: 300px;
        z-index: -1;
        pointer-events: none;

        &::after {
            position: relative;
            display: block;
            content: "";
            background-image: url(../img/alt-watermark.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom left;
            opacity: 0.1;
            pointer-events: none;
            width: 100%;
            height: 500px;
        }
    }

    .bg-custom-grad {
        position: absolute;
        top: 0;
        right: -270px;
        background-image: url(../img/bg-grad.png);
        background-repeat: repeat-x;
        width: 785px;
        height: 1131px;
        pointer-events: none;
        z-index: -1;
    }
}

// Team Members
.team-members {
    .intro {
        width: 100%;
        padding: 1em 0;
        text-align: center;
        background-image: linear-gradient(to bottom left, #249396 7.9%, #187c82 20.1%, #0c646d 36.5%, #085b66 46.5%, #0b515c 58.5%, #17333f 100%);
        color: var(--color-white);

        h3 {
            font-size: 55px;
            font-weight: 700;
            margin: 0 0 20px;
        }

        @media (min-width: $bp-large) {
            padding-bottom: 6em;
        }
    }

    @media (min-width: $bp-large) {
        .container {
            position: relative;
            top: -75px;
        }
    }

    .the-team {
        text-align: center;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        .member {
            width: 100%;
            
            margin: 0 auto;
        }

        @media (min-width: $bp-large) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px 77px;
            align-items: auto;

            .member {
                width: 270px;
                text-align: left;
            }
        }

        img {
            position: relative;
            max-width: 270px;
            border-radius: 50%;
            margin-bottom: 10px;
        }

        span {
            display: block;
            font-weight: 600;
            font-size: 22px;
        }

        p.text-small {
            font-size: 18px;
            font-weight: 400;
        }

        .btn {
            grid-column: 1 / 4;
            justify-self: center;
            align-self: center;
        }
    }
}

.event-content {
    position: relative;
    overflow: visible;
    padding: 55px 65px;
    margin-bottom: 55px;

    .event-date {
        display: block;
        position: absolute;
        top: 0;
        color: var(--color-primary);

        span {
            background: var(--color-white);
            border: 1px solid var(--color-primary);
            display: inline-block;
            font-weight: bolder;
            line-height: normal;
            padding: 9px 25px 3px;
            position: relative;
            top: -30px;
        }
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-primary);
        position: absolute;
        top: 0;
        left: 0;
    }

    &.no-before::before {
        display: none;
    }
}

.column {
    &.left {
        .event-text,
        .event-title {
            display: block;
            text-align: right;
        }
        .event-date {
            left: 0;
        }
    }

    &.right .event-date {
        right: 0;
    }
}

.bg-black {
    .column {
        border-left-color: var(--color-white);

        .event-content {
            .event-date {
                color: var(--color-white);

                span {
                    border-color: var(--color-white);
                    background-color: var(--color-primary-darkest);
                }
            }

            &::before {
                background-color: var(--color-white);
            }
        }
    }
}

.end-event {
    position: relative;
    grid-column: 1 / span 2;
    align-self: center;
    justify-self: center;

    .event-date {
        display: block;
        position: absolute;
        top: 0;
        color: var(--color-primary);

        span {
            background: var(--color-white);
            border: 1px solid var(--color-primary);
            display: inline-block;
            font-weight: bolder;
            line-height: normal;
            padding: 9px 25px 3px;
            position: relative;
            top: 0;
            left: -56px;
        }
    }

    &::before {
        content: "";
        display: block;
        width: 1px;
        height: 200px;
        background-color: var(--color-primary);
        position: absolute;
        top: -200px;
        left: -1px;
    }
}

.tug {
    position: relative;
    top: -10px;
}

.end-quote {
    cite {
        color: var(--color-black);
        margin-bottom: 1em;
    }
}

.result {
    position: relative;
    padding: 1em;
    overflow: visible;
    h4 {
        font-size: 1.25em;
        font-weight: 600;
        color: #17333f;
    }
    .user {
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        padding-left: 50px;
        span {
            color: var(--color-black);
            font-weight: 700;
            font-size: 22px;
        }
        img {
            width: 100%;
            max-width: 63px;
            height: 100%;
            max-height: 63px;
            border-radius: 50%;
            margin-right: 1em;
        }
    }

    .price {
        display: block;
        color: #249396;
        font-size: 30px;
        font-weight: 700;
        padding-left: 50px;
    }
}

.bg-box {
    position: absolute;
    top: 0;
    left: -10px;
    width: calc(100% + 20px);
    height: 80%;
    background-color: #fafafa;
    border: 1px solid #249396;
    z-index: -1;
    pointer-events: none;
}

.hero-boxes {
    position: relative;
    padding-top: 0;
    margin-top: -65px;
    overflow: visible;
    margin-bottom: 6em;
}

.grid-fourth-alt {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    align-items: stretch;
    justify-items: center;
    margin: 0 auto;
    max-width: 1200px;

    .grid-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: var(--color-white);
        border: 2px solid var(--color-primary-light);
        width: 100%;
        min-height: 163px; // Ensure the boxes have a minimum height

        span {
            position: relative;
            color: var(--color-primary);
            font-weight: 700;
            font-size: 30px;
            line-height: 1.2;
            margin-bottom: 10px;
            &::after {
                content: "";
                display: block;
                width: 50%;
                height: 3px;
                background-color: #B77B0B;
                position: absolute;
                bottom: -20px;
                left: 50%;
                margin-left: -25%;
            }
        }
    }
}

.grid-item.last {
    border-top-right-radius: 50px;
}

// Media query for mobile responsiveness
@media (max-width: 768px) {
    .grid-fourth {
        grid-template-columns: 1fr; // Stack the grid items on mobile
    }
}

.grid-two {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1200px;
    margin-bottom: 8em;

    @media (min-width: 80em) {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .content {
        grid-area: 1;
    }

    .images {
        grid-area: 1/2;
        text-align: right;
        display: none;
        @media (min-width: 80em) {
            display: inline;
        }
    }
}

.p-1 {
    margin-top: 0;
    margin-bottom: 1em;
}

/* Padding All Sides */
.p-0 {
    padding: 0;
}
.p-0_5 {
    padding: 0.125rem;
} /* 0.5 * 0.25rem */
.p-1 {
    padding: 0.25rem;
}
.p-1_5 {
    padding: 0.375rem;
} /* 1.5 * 0.25rem */
.p-2 {
    padding: 0.5rem;
}
.p-2_5 {
    padding: 0.625rem;
} /* 2.5 * 0.25rem */
.p-3 {
    padding: 0.75rem;
}
.p-3_5 {
    padding: 0.875rem;
} /* 3.5 * 0.25rem */
.p-4 {
    padding: 1rem;
}
.p-6 {
    padding: 1.5rem;
}
.p-7 {
    padding: 1.75rem;
}
.p-8 {
    padding: 2rem;
}
.p-9 {
    padding: 2.25rem;
}
.p-10 {
    padding: 2.5rem;
}
.p-11 {
    padding: 2.75rem;
}
.p-12 {
    padding: 3rem;
}
/* and so on... */

/* Padding Top */
.pt-0 {
    padding-top: 0;
}
.pt-0_5 {
    padding-top: 0.125rem;
}
.pt-1 {
    padding-top: 0.25rem;
}
.pt-1_5 {
    padding-top: 0.375rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-2_5 {
    padding-top: 0.625rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-3_5 {
    padding-top: 0.875rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-5 {
    padding-top: 1.25rem;
}
.pt-6 {
    padding-top: 1.5rem;
}
.pt-7 {
    padding-top: 1.75rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pt-9 {
    padding-top: 2.25rem;
}
.pt-10 {
    padding-top: 2.5rem;
}
.pt-11 {
    padding-top: 2.75rem;
}
.pt-12 {
    padding-top: 3rem;
}

/* Padding Right */
.pr-0 {
    padding-right: 0;
}
.pr-0_5 {
    padding-right: 0.125rem;
}
.pr-1 {
    padding-right: 0.25rem;
}
.pr-1_5 {
    padding-right: 0.375rem;
}
.pr-2 {
    padding-right: 0.5rem;
}
.pr-2_5 {
    padding-right: 0.625rem;
}
.pr-3 {
    padding-right: 0.75rem;
}
.pr-3_5 {
    padding-right: 0.875rem;
}
.pr-4 {
    padding-right: 1rem;
}
.pr-5 {
    padding-right: 1.25rem;
}
.pr-6 {
    padding-right: 1.5rem;
}
.pr-7 {
    padding-right: 1.75rem;
}
.pr-8 {
    padding-right: 2rem;
}
.pr-9 {
    padding-right: 2.25rem;
}
.pr-10 {
    padding-right: 2.5rem;
}
.pr-11 {
    padding-right: 2.75rem;
}
.pr-12 {
    padding-right: 3rem;
}

/* Padding Bottom */
.pb-0 {
    padding-bottom: 0;
}
.pb-0_5 {
    padding-bottom: 0.125rem;
}
.pb-1 {
    padding-bottom: 0.25rem;
}
.pb-1_5 {
    padding-bottom: 0.375rem;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-2_5 {
    padding-bottom: 0.625rem;
}
.pb-3 {
    padding-bottom: 0.75rem;
}
.pb-3_5 {
    padding-bottom: 0.875rem;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-5 {
    padding-bottom: 1.25rem;
}
.pb-6 {
    padding-bottom: 1.5rem;
}
.pb-7 {
    padding-bottom: 1.75rem;
}
.pb-8 {
    padding-bottom: 2rem;
}
.pb-9 {
    padding-bottom: 2.25rem;
}
.pb-10 {
    padding-bottom: 2.5rem;
}
.pb-11 {
    padding-bottom: 2.75rem;
}
.pb-12 {
    padding-bottom: 3rem;
}

/* Padding Left */
.pl-0 {
    padding-left: 0;
}
.pl-0_5 {
    padding-left: 0.125rem;
}
.pl-1 {
    padding-left: 0.25rem;
}
.pl-1_5 {
    padding-left: 0.375rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-2_5 {
    padding-left: 0.625rem;
}
.pl-3 {
    padding-left: 0.75rem;
}
.pl-3_5 {
    padding-left: 0.875rem;
}
.pl-4 {
    padding-left: 1rem;
}
.pl-5 {
    padding-left: 1.25rem;
}
.pl-6 {
    padding-left: 1.5rem;
}
.pl-7 {
    padding-left: 1.75rem;
}
.pl-8 {
    padding-left: 2rem;
}
.pl-9 {
    padding-left: 2.25rem;
}
.pl-10 {
    padding-left: 2.5rem;
}
.pl-11 {
    padding-left: 2.75rem;
}
.pl-12 {
    padding-left: 3rem;
}

/* Grid Container Classes */
.grid {
    display: grid;
    gap: 1rem; /* Default gap between grid items */
}

.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
}

/* Responsive Grid Classes */
@media (max-width: 768px) {
    .grid-cols-2 {
        grid-template-columns: 1fr;
    }

    .grid-cols-4 {
        grid-template-columns: 1fr;
    }
}

/* Column Span Utility Classes */
.col-span-1 {
    grid-column: span 1 / span 1;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.col-span-3 {
    grid-column: span 3 / span 3;
}

.col-span-4 {
    grid-column: span 4 / span 4;
}

/* Grid Gap Utility Classes */
.grid-gap-0 {
    gap: 0;
}
.grid-gap-1 {
    gap: 1px;
}
.grid-gap-2 {
    gap: 2px;
}
.grid-gap-3 {
    gap: 3px;
}
.grid-gap-4 {
    gap: 4px;
}
.grid-gap-5 {
    gap: 5px;
}
.grid-gap-6 {
    gap: 6px;
}
.grid-gap-7 {
    gap: 7px;
}
.grid-gap-8 {
    gap: 8px;
}
.grid-gap-9 {
    gap: 9px;
}
.grid-gap-10 {
    gap: 10px;
}
.grid-gap-12 {
    gap: 12px;
}
.grid-gap-14 {
    gap: 14px;
}
.grid-gap-16 {
    gap: 16px;
}
.grid-gap-18 {
    gap: 18px;
}
.grid-gap-20 {
    gap: 20px;
}
.grid-gap-24 {
    gap: 24px;
}
.grid-gap-28 {
    gap: 28px;
}
.grid-gap-32 {
    gap: 32px;
}
.grid-gap-36 {
    gap: 36px;
}
.grid-gap-40 {
    gap: 40px;
}
.grid-gap-44 {
    gap: 44px;
}
.grid-gap-48 {
    gap: 48px;
}
.grid-gap-50 {
    gap: 50px;
}

.community-box {
    position: relative;
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
    display: none;
    
    @media (min-width: 80em) {
        padding-top: 100px;
        padding-bottom: 150px;
        display: block;
    }
}

.community {
    grid-template-columns: repeat(7, 1fr);
    width: 100%;


    .community-item {
        display: grid;
        grid-template-rows: repeat(1fr);
        flex-direction: column;
        align-items: center;
        text-align: center;
        
        font-weight: 400;
        font-size: 20px;
        &.extra {
            color: #249396;
            font-weight: 600;
        }
        &.extra > img {
            margin-bottom: 20px;
            min-width: 179px;
        }
    }

    .end {
        align-self: center;
    }

    .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 179px;

        img {
            max-width: 40px;
        }
    }
}

.weTeach .grid-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    span {
        font-size: 30px;
        font-weight: 600;
        color: #249396;
    }
}

@media (min-width: 80em) {
    #why-us .row-home-about-tony {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    #why-us .row-home-about-tony.custom .grid-img {
        height: 980px;
        grid-area: 1 / 1 / 3 / 6;
        margin-right: 0;
        align-self: end;
    }

    #why-us .row-home-about-tony .grid-text {
        grid-area: 2 / 6 / 3 / 12;
        padding-bottom: 7.5em;
        max-width: 600px;
        box-sizing: content-box;
    }

    #why-us .row-home-about-tony .grid-heading {
        grid-area: 1 / 6 / 2 / 12;
        padding-top: 4.5em;
        padding-left: 0;
    }

    div.anchor {
        position: relative;
    }

    div.anchor::after {
        content: "";
        position: absolute;
        bottom: -51px;
        right: -38px;
        background-image: url(../img/whyus/shape-floater-02.png);
        width: 154px;
        height: 139px;
    }
}

.custom-block-one {
    position: relative;
    background-image: url(../img/whyus/background.png);
    background-position: top center;
    background-repeat: none;
    padding-top: 5.5em;
    padding-bottom: 10em;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
        color: var(--color-white);
    }

    .ustla {
        position: absolute;
        bottom: -145px;
        left: 0;
        width: 100%;
        background-image: url(../img/whyus/ustla.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 545px;
        z-index: 0;
        pointer-events: none;
    }
}

.case-study-h4 {
    font-weight: 700 !important;
    font-size: 24px !important;
    margin: 0 0 0 0 !important;
    padding-left: 50px;
}

.whyus-video {
    position: relative;
    top: -100px;
    padding-bottom: 0;

    @media (min-width: 80em) {
        top: -160px;
    }
}

#why-us .section-carousel {
    left: auto;
    right: 0;
}

#why-us .grid-start-half {
    grid-column-start: 2;
    width: 528px;

    h2 {
        font-size: 50px;
        font-weight: 700;
        color: #249396;
        margin-bottom: 0;
    }

    p.thirty {
        line-height: 1.2;
    }
}

#why-us .list-inline {
    justify-content: end;
    max-height: 579px;
    overflow: hidden;
}

#why-us .custom-h2 {
    font-size: 50px;
    color: #249396;
}

.letter {
    position: relative;
    width: 100%;
    background-image: url(../img/whyus/letter-bottom.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: #f4f3f0;
    padding:0 10px 100px;
    overflow: hidden;
    @media (min-width: 80em) {
        padding: 0 10px 600px;
    }
}

.letter > .wrapper {
    position: relative;
    background-color: #fafafa;
    max-width: 1300px;
    padding: 0 10px;
}

.paper-shadow {
    position: relative;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    //box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.1), -2px 2px 38px rgba(0, 0, 0, 0.1); /* Remove top shadow */
  }
  
//   .paper-shadow:after, .paper-shadow:before {
//     content: '';
//     position: absolute;
//     left: auto;
//     background: none;
//     z-index: -1;
//   }
  
//   .paper-shadow:after {
//     width: 90%;
//     height: 10px;
//     top: 0; /* Remove top shadow */
//     bottom: 0; /* Apply to bottom */
//     right: 8px;
//     transform: rotate(-3deg);
//     box-shadow: 0px 25px 35px 0px rgba(0,0,0,0.1); /* Only bottom shadow */
//   }
  
//   .paper-shadow:before {
//     width: 10px;
//     height: 95%;
//     top: 5px;
//     right: 18px;
//     transform: rotate(3deg);
//     box-shadow: 22px 0px 35px 0px rgba(0,0,0,0.1); /* Only right shadow */
//   }

  .constrict {
    width: 100%;
    max-width: 754px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 50px;
    @media (min-width: 80em) {
        padding-top: 100px;
        padding-bottom: 50px;
    }
  }
  

  .touchpoint {
    font-weight: 500;
  }


@media (min-width: 90em) {
    #why-us .h1-hero {
        font-size: 55px;
        font-weight: 600;
    }
}

/* Manually Set Font Sizes */
.intro-hero{
    font-size: 16px;
    font-weight: 700;
}

.h2-fifty {
    font-size: 50px;
    font-weight: 700;
    margin: 0 0 10px 0;
    padding: 0;
}

.h3-fifty {
    font-size: 50px;
    font-weight: 700;;
    margin: 0 0 20px 0;
    padding: 0;
}

.twenty {
    margin-top: 20px;
    font-size: 20px;
}

.twentytwo {
    font-size: 22px;
    font-weight: 600;
}

.thirty {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 500;
}

.thirtyfour {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 20px;
}

.fortyfive {
    font-size: 45px;
    font-weight: 700;
}

.nine-o-six {
    margin: 0 auto var(--spacing);
    width: 100%;
    max-width: 906px;
}

.custom-paragraph {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 10px 0;
}

.custom-alt-paragraph {
    font-size: 22px;
}

.reasons h3 {
    color: #085B66;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 15px;
}

.reasons .line-accent {
    margin-bottom: 15px;
}

.reasons p {
    font-size: 18px;
    font-weight: 400;
}


/* Hard Set Sizes for images on page */
.tony-why-01 {
    position: relative;
    grid-area: 1;
    align-content: flex-start;
    text-align: right;

        img {
            min-height: 680px;
        }

        &:after {
            content:"";
            display: block;
            z-index: -1;

            position: absolute;
            top:-50px;
            right:-30px;
            width: 158px;
            height: 150px;
            background-image: url(../img/whyus/shape-floater.png);
        }
}

.experts-img {
    max-width: 1370px;
    margin: 0 auto 40px;
}

@media (min-width: 80em) {

    #why-us .row-tony-continues {
        margin-top: -245px;
    }

}

@media (min-width: $bp-large) {
.whyus-video::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 1600px;
    background: url(../img/whyus/rectangle.png) no-repeat;
    background-position: bottom right;
    z-index: -1;
}}

.another-custom-grad {
    background-image: linear-gradient(to bottom left, #249396 0%, #085b66 39.9%, #0b515c 58.5%, #17333f 100%);
}

#why-us .bg-semi-transparent-dark {
    background-color: rgba(0, 0, 0, 0.2);
}


.footer-new-h1 {
    font-size: 65px;
    font-weight: 600;
    color: #fff;
    margin:0 auto 15px;
    padding:0;
}

.footer-paragraph {
    font-size: 18px;
    color: #fff;
    max-width: 630px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.new-footer-btn {
    margin-top: 15px;
    margin-bottom: 10px;
}

.notice {
    color: #fff;
    text-align: left;
    font-size: 15px;
    font-weight: regular;
}

#why-us .hero-footer a {
    color: #fff;
}

.pen {
    position: absolute;
    top: 25%;
    right: -325px;
    width: 593px;
    height: 341px;
    background-image: url(../img/whyus/pen.png);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
}


@media (max-width: 90em) {
    .hide-mobile {
        display: none;
    }
}
header {
  position: relative;
}

/**
 * @section Main Nav
 * Styling for the main navigation area
 */

 .js-hamburger .nav-expanded {
	background-color: var(--color-primary);
}

/**
 * The navigation wrapper
 */
.nav-wrap {
	font-size: 0.9em;
	font-weight: bold;
	padding-top: 1.72em; // brought this in line with the mockups (25px)
	padding-bottom: 0.5em;
	text-align: center;


	.hamburger {
		display: none;
	}

	.js-hamburger & {
		@media (max-width: $bp-large) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr;
			column-gap: 1em;
			padding: 1em 0.5em 0.5em;

			.logo {
				grid-area: 1 / 1 / 2 / 2;
				text-align: left;
			}
			.nav-menu { grid-area: 2 / 1 / 3 / 3; }
			.hamburger {
				display: block;
				grid-area: 1 / 2 / 2 / 3;
			}

			.js-hidden {
				display: none;
			}
		}
	}

	@media (min-width: $bp-large) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

/**
 * The logo
 */
.logo {
	display: inline-block;
	font-size: 1.2em;
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: 0.3125em;
	text-decoration: none;

	@media (min-width: $bp-large) {
		margin-bottom: 0;
	}

	&,
	&:focus {
		color: var(--color-white);
	}

	&:active,
	&:hover {
		color: var(--color-white);
	}
}

/**
 * The navigation menu
 */
.nav {
	@extend .list-inline;
	@extend .list-inline-spaced;
	align-items: center;
	margin-bottom: 0;

	a {
		color: var(--color-white);
		text-decoration: none;
	}

	a:hover:not(.btn),
	a:active:not(.btn),
	a:focus:not(.btn),
	.active:not(.btn) {
		border-bottom: 0.125em solid var(--color-accent);
		color: var(--color-white);
		// text-decoration: none;
	}

    [aria-current="page"]:not(.btn) {
        color:  var(--color-primary-light);
    }

	.js-hamburger & {
		@media (max-width: $bp-large) {
			// margin-left: 0;
			// margin-right: 0;
			display: block;
		}
	}

	.js-hamburger & > li {
		@media (max-width: $bp-large) {
			display: block;
			margin: 0.5em 0;
			text-align: left;
		}
	}

	/**
	 * Dropdown
	 */

	/**
	 * Position the dropdown content
	 */
	details {
		display: inline-block;
		position: relative;
        list-style: none;

        /* styles the drop down arrow */
        > summary:first-of-type {
            position: relative;
            list-style-type: none;
            padding-right: 10px;

            &::after {
                content: '';
                width: 0;
                height: 0;
                transform: rotate(180deg);
                border-left: 4px solid transparent;  
                border-right: 4px solid transparent; 
                border-bottom: 4px solid var(--color-accent);
                display: inline-block;
                position: absolute;
                right: -5px;
                top: 10px;
            }
        }
	}


	/**
	 * Style the dropdown list
	 */
	details ul {
		@extend .list-unstyled;
		background-color: var(--color-primary);
		border: 1px solid var(--color-primary-dark);
		border-radius: 0.25em;
		min-width: 19em;
		position: absolute;
		text-align: left;
		top: 2.5em;
		left: -2em;
		z-index: 999;
        list-style: none;
		li {
			padding: 0.25em 0.5em;
		}


		.js-hamburger & {
			@media (max-width: $bp-large) {
				background-color: transparent;
				border: none;
				min-width: auto;
				position: static;
				top: unset;
				left: unset;

				li {
					padding: 0;
				}
			}
		}
	}

}
/**
 * @section Buttons
 * Styling for CSS buttons.
 */


/**
 * Primary buttons
 */
.btn {
	background-color: var(--color-accent);
	border: 1px solid var(--color-accent);
	border-radius: 2em;
	display: inline-block;
	font-size: 0.9375em;
	font-weight: bold;
	line-height: 1.2;
	margin-right: 0.3125em;
	margin-bottom: 0.3125em;
	padding: 1.2125em 3.3em;
	text-decoration: none;
	white-space: normal;

	&,
	&:active,
	&:focus,
	&:hover,
	&:visited {
		color: var(--color-white);
	}

	&:hover,
	&:active {
		background-color: var(--color-accent-dark);
		border-color: var(--color-accent-dark);
		// color: var(--color-white);
		text-decoration: none;
	}
}


/**
 * Secondary buttons
 */
.btn-secondary {
	background-color: var(--color-primary-light);
	border-color: var(--color-primary-light);

	// &,
	// &:focus,
	// &:visited {
	// 	color: var(--color-white);
	// }

	&:hover,
	&:active {
		background-color: var(--color-primary);
		border-color: var(--color-primary);
	}
}

/**
 * Accent buttons
 */
.btn-tertiary {
	background-color: var(--color-white);
	border-color: var(--color-primary-light);

	&,
	&:active,
	&:focus,
	&:hover,
	&:visited {
		color: var(--color-primary-light);
	}

	&:hover,
	&:active {
		background-color: var(--color-gray);
		border-color: var(--color-primary-light);
	}
}

/**
 * Button styled like a link
 */
.btn-link {
	background: transparent;
	border: 0;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;

	&,
	&:focus,
	&:visited {
		color: inherit;
	}

	&:hover,
	&:active {
		background-color: transparent;
		text-decoration: inherit;
	}

}


/**
 * Active state
 */
.btn:active {
	box-shadow: inset 0 0.15625em 0.25em rgba(0, 0, 0, 0.15), 0 1px 0.15625em rgba(0, 0, 0, 0.05);
	outline: 0;
}


/**
 * Disabled state
 */
.btn[disabled] {
	box-shadow: none;
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}


/**
 * Button size
 */

.btn-large {
	font-size: 1.2em;
	line-height: normal;
	// padding: 0.6875em 0.9375em;
}

.btn-small {
	padding: 0.5em 2em;
}

.btn-wide {
	padding-left: 7em;
	padding-right: 7em;
}

.btn-narrow {
	padding-left: 1em;
	padding-right: 1em;
}

.btn-circle {
	border-radius: 50%;
	padding: 0.45em;
	margin-bottom: 0;
	height: 2em;
	width: 2em;
}


/**
 * Block-level buttons
 */
.btn-block {
	display: block;
	margin-right: 0;
	padding-right: 0;
	padding-left: 0;
	width: 100%;
}


/**
 * General styles
 */
.btn,
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
}


/**
 * Remove right margin on last element and inputs
 */
.btn:last-child,
input.btn {
	margin-right: 0;
}
/**
 * @section Custom Grid Layouts
 * Structure and layout
 */

.highlights {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 0.5em;

	& > :not(:first-child) {
		border-left: 1px solid var(--color-gray-dark);
		padding-left: 1em;
	}
}

.row-testimonial {
	display: flex;
	align-items: center;
	column-gap: 0.75em;
	margin-bottom: 0.5em;
	padding-top: 0.5em;

    img {
        border-radius: 50%;
    }

    svg {
        margin-right: 4px;
    }
}


@media (min-width: $bp-large) {

	.section-block {
		display: grid;
		position: relative;

		.block-element {
			background-color: var(--color-primary-light);
			height: 12em;
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			z-index: -1;
		}

		.block-element-large {
			background-color: var(--color-primary-light);
			height: 26em;
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			z-index: -1;
		}
	}

	.section-block-alt {
		display: grid;
		position: relative;

		.block-element {
			background-color: var(--color-primary-light);
			height: 18em;
			position: absolute;
			top: 0;
			right: 0;
			width: 42%;
			z-index: -1;

            display: flex;
		}
	}


.logo-overlay-alt {
    position: relative;
    width: 100%;
    height: 18em;
    @media (min-width: $bp-large) {
        &::before {
            content: "";
            position: absolute;
            top: 58px;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url('../img/alt-watermark.svg');
            background-size: 100%; // or auto
            background-repeat: no-repeat;
            background-position: center;
            opacity: 10%;  
            z-index: 0;
            pointer-events: none;
        }
    }
}

	.section-block-and-carousel {
		background: linear-gradient(90deg, #ffffff 0%, #ffffff 80%, #1e323e 80%, #1e323e 100%);
		margin-top: -3em;
		padding-top: 4em;
		position: relative;

		.section-carousel {
			position: absolute;
			right: 0;
			width: 50%;
		}
	}

	.section-no-block-carousel {
		margin-top: -3em;
		padding-top: 4em;
		position: relative;

		.section-carousel {
			position: absolute;
			right: 0;
			width: 48%;
		}
	}

	.section-no-block-carousel-alt {
		margin-top: -3em;
		padding-top: 4em;
		position: relative;

		.section-carousel {
			position: absolute;
			left: 0;
			width: 52%;
		}
	}

	.row-opportunity {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr;
		column-gap: 7em;

		.grid-img { grid-area: 1 / 1 / 3 / 6; }
		.grid-heading {
			grid-area: 1 / 5 / 2 / 11;
			padding-left: 5.5em;
		}
		.grid-text { grid-area: 2 / 6 / 2 / 10; }
	}

	.row-why-in-business {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr;
		column-gap: 0;

		.grid-img { grid-area: 1 / 1 / 3 / 6; }
		.grid-text {
			grid-area: 2 / 6 / 2 / 11;
			padding: 8em 6em 8em 5em;
		}
	}

	.row-home-about-tony {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr;
		column-gap: 1em;

		.grid-img {
			grid-area: 1 / 1 / 3 / 7;
			margin-right: 6em;
		}
		.grid-heading {
			grid-area: 1 / 5 / 2 / 12;
			padding-left: 5.5em;
		}
		.grid-text {
			grid-area: 2 / 6 / 2 / 10;
			padding-left: 3em;
		}
	}

	.row-knowing {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr;
		column-gap: 6em;

		.grid-img { grid-area: 1 / 1 / 3 / 8; }
		.grid-heading {
			grid-area: 1 / 5 / 2 / 13;
			padding-left: 2em;
		}
		.grid-text { grid-area: 2 / 7 / 2 / 13; }
	}

	.row-outcomes {
		max-width: 54em;
		margin: 0 auto;
	}

	.row-home-bonus {
		margin: 0 auto;
		width: 88%;
	}

	.row-fraction {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: 1em;
		row-gap: 2em;

		.grid-img { grid-area: 1 / 1 / 2 / 6; }
		.grid-text { grid-area: 1 / 6 / 2 / 11; }
		.grid-bonus { grid-area: 2 / 2 / 3 / 13; }
	}

	.row-compress {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr;

		.grid-bg {
			grid-area: 1 / 1 / 2 / 9;
			background-color: var(--color-primary-dark);
			background-image: linear-gradient(225deg, var(--color-primary-light), var(--color-primary-dark));
			border-bottom-right-radius: 9em;
		}

		.grid-content { grid-area: 1 / 1 / 2 / 13; }
	}

	.row-blog-featured {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(4, 1fr);
		gap: 1em;

		.grid-featured-main { grid-area: 1 / 1 / 5 / 2; }
		.grid-featured-1 { grid-area: 1 / 2 / 2 / 3; }
		.grid-featured-2 { grid-area: 2 / 2 / 3 / 3; }
		.grid-featured-3 { grid-area: 3 / 2 / 4 / 3; }
		.grid-featured-4 { grid-area: 4 / 2 / 5 / 3; }
	}

}

.row-about-tony-img {

    display: none;
	text-align: center;

    img.tony {
        border: 1px solid white;
        border-top-left-radius: 40%;
    }

	.grid-accent {
		display: none;
	}

	@media (min-width: $bp-medium) {
        display: inline-block;
		text-align: left;
		position: relative;

		.grid-accent {
			display: inline-block;
			position: absolute;
			top: -1.5em;
			right: -2.5em;
		}
	}
}

@media (max-width: $bp-large) {
	.row-blog-featured {
		.grid-featured-main,
		.grid-featured-1,
		.grid-featured-2,
		.grid-featured-3,
		.grid-featured-4, {
			margin-bottom: 1em;
		}
	}
}

@media (min-width: $bp-medium) {

	.row-featured-card {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr auto;
		column-gap: 1.5em;
		height: 100%;
	    min-height: 0;

		.grid-card-img {
			background-size: cover;
			grid-column: auto / span 5;
		}

		.grid-card-text {
			grid-column: auto / span 7;
			padding: 1em 1em 1em 0;
		}
	}

}

@media (max-width: $bp-medium) {
	.row-featured-card .grid-card-img {
		background-size: cover;
		min-height: 16em;
	}

	.row-featured-card .grid-card-text {
		padding: 1em 1em 1em 1em;
	}
}

@media (max-width: $bp-large) {
	.grid-bg-small {
		background-color: var(--color-primary-dark);
		background-image: linear-gradient(225deg, var(--color-primary-light), var(--color-primary-dark));
		border-bottom-right-radius: 9em;
	}

	.row-opportunity,
	.row-home-about-tony {
		margin: 0 auto;
		width: 88%;

		.grid-heading {
			padding-top: 2em;
		}

		.grid-text {
			padding-bottom: 2em;

			& > .padding-bottom-xlarge {
				padding-bottom: 0;
			}
		}
	}

	.row-opportunity h2.padding-top-xlarge {
		padding-top: 0;
	}

	.row-opportunity .grid-text.padding-bottom-xlarge {
		padding-bottom: 2.75em;
	}

	.row-opportunity .grid-text .padding-bottom-xlarge {
		padding-bottom: 0;
	}
}

@media (max-width: $bp-large) {
	.grid-bg-small-alt {
		background-color: var(--color-primary-dark);
		background-image: linear-gradient(45deg, var(--color-primary-light), var(--color-primary-dark));
		border-bottom-left-radius: 9em;
	}
}

@media (min-width: $bp-large) {
	.row-tony-continues {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr;
		margin-top: -8em;

		.grid-bg {
			grid-area: 1 / 5 / 2 / 13;
			background-color: var(--color-primary-dark);
			background-image: linear-gradient(45deg, var(--color-primary-light), var(--color-primary-dark));
			border-bottom-left-radius: 9em;
			margin-left: 4em;
		}

		.grid-content {
			grid-area: 1 / 1 / 2 / 13;
			padding-top: 10em;
		}
	}
}

@media (max-width: $bp-large) {
	.row-tony-continues .grid-content.padding-top-xlarge {
		padding-top: 2em;
	}
}

.row-blockquote {

	@media (max-width: $bp-large) {
		.grid-icon {
			display: none;
		}
	}

	@media (min-width: $bp-large) {
		display: grid;
		grid-template-columns: 1fr 4fr 1fr;
		grid-column-gap: 0.5em;

		.grid-icon { grid-area: 1 / 1 / 2 / 2; }
		.grid-quote {
			font-size: 1.75em;
			grid-area: 1 / 2 / 2 / 3;
		}
	}
}

.login-logo {
	@media (min-width: $bp-large) {
		display: flex;
		align-items: flex-start;
		min-height: 100vh;
	}
}

/* hero results - about Tony intro */
.results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 0; 
    align-items: start;

    margin-bottom: 25px;

    h3 {
        /* reset for this element */
        margin: 0;
        padding: 0;
    }

    .item {
        padding: 0 1.25em;
        span {
            font-size: .85em;
        }
    }

    .middle {
        border-left: 1px solid rgba(255,255,255, 0.25);
        border-right: 1px solid rgba(255,255,255, 0.25);
    }
}

/* My Results Section - About Tony */
.my-results {
    position: relative;
    @media (min-width: $bp-large) {
        display: grid;
        grid-template-columns: 1fr 1fr;  // Two equal columns
        grid-gap: 20px;                  // Gap between grid items

        & > div:first-child {            // Targeting the first div
            grid-column: 1 / 3;            // Spanning the header across both columns
            text-align: center;            // Centering the text in the header
            
            h3 {
                margin: 0;                   // Resetting default margin for clean design
            }
            
            p {
                margin-top: 15px;            // Space after the header for the text
            }
        }
    }

    .images {
        position: relative;
        display: none;
        @media (min-width: $bp-large) {
            display: inline-block;
            padding-left: 55px;
            padding-right: 35px;
            padding-top: 15px;
            & > img {
                position: relative;
                z-index: 10;
            }
        }

        &::before {
            position: absolute;
            top: -55px;
            right: 0;
            display: block;
            content: '';
            width: 245px;
            height: 225px;
            background-color: #070707;
            border-top-left-radius: 40%;
            z-index: 0;
        }
    }

}

/* Hacks for this print design that's trying hard to be a website */
/* this is not pretty - but it works - but it's gross */
.feed-section {
    display: flex;
    flex-direction: column;

    .heading {
        width: 100%;
        text-align: center;
    }

    .post {
        position: relative;
        border: 1px solid var(--color-primary-middle);
        padding: 44px;
        margin-bottom: 2em;
    }

    .flex-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    img {
        margin-top: 1em;
    }

    .author {
        display: block;
        font-weight: bolder;
    }

    @media (min-width: 800px) {
        .articles {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 25px; /* Gap between child elements */

            .post {
                flex: 1; /* This will make each post take up equal width */
                width: calc(50% - 2px); /* Subtract the 1px border from each side */
            }
        }
    }
}

a.read-more {
    position: relative;
    text-transform: uppercase;
    color: var(--color-accent);
    text-decoration: none;
    font-weight: bold;;

    &::after {
        content: '→';
        color: var(--color-accent);
        padding-left: 2px;
        font-size: 1.5em;
        position: relative;
        bottom: -3px;
    }

    &:hover::after {
        color: var(--color-primary-dark);
    }
}


.flex-hack-purpose {
    display: flex;
    flex-direction: column;
    @media (min-width: $bp-large) {
        flex-direction: row;
        & > div {
            flex: 1; 
            width: calc(50% - 2px); 
        }
    }
    .right {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .constrant {
        margin: auto;
        width: 100%;
        max-width: 365px;
    }

    .left {
        position: relative;

        h3, span {
            position: relative;
            z-index: 10;
        }

        h3 {
            font-size: 4em;
            margin-bottom: 0;
            padding-bottom: .5em;
        }

        span {
            display: block; 
            max-width: 440px;
        }

        .text-alt {
            font-size: 1.2125em;
            line-height: 1.4;

            @media (min-width: $bp-medium) {
                font-size: 1.3em;
            }
        }
    }


}

.fixedimg {
    display: none;
    @media (min-width: $bp-large) {
        display: block;
    }
    position: absolute;
    top: 0;
    right: -80px;
    max-width: 569px;
    z-index: 0;
    pointer-events: none;
}

.row-tony-continues {
    position: relative;
}

// .watermark-custom {
//     height: 18em;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
// }

// .watermark-custom:after {
//     content: "";
//     position: absolute;
//     top: 58px;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-image: url(../img/alt-watermark.svg);
//     background-size: 100%;
//     background-repeat: no-repeat;
//     background-position: center;
//     opacity: 10%;
//     z-index: 1;
//     pointer-events: none;
// }

/**
 * @section Cards
 * UI for card elements
 */

.card {
	background-color: var(--color-white);
	position: relative;
}

.card-muted {
	background-color: var(--color-gray);
}

.card-primary {
	background-color: var(--color-primary-light);
	color: var(--color-white);
}

.card-primary-dark {
	background-color: var(--color-primary-dark);
	color: var(--color-white);
}

.card-grid {
	display: grid;
	// grid-template-columns: 1fr;
	// grid-template-rows: repeat(2, 1fr);
	grid-row-gap: 1em;

	.card-text { grid-area: 1 / 1 / 2 / 2; }
	.card-img { grid-area: 2 / 1 / 3 / 2; }
}

.card-number {
	background-color: var(--color-primary-dark);
	border-radius: 50%;
	display: inline-block;
	font-weight: 900;
	position: absolute;
	height: 2.5em;
	width: 2.5em;
	padding: 0.5em;
	font-size: 2em;
	top: -0.75em;
	left: 0.75em;
}

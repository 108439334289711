.careers-bg {
    background-image: url(../img/careers-banner.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

ul.icon-list {
    margin: 0;
}

ul.icon-list li {
    position: relative;
    list-style: none;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 25px;
}

ul.icon-list li::before {
    position: absolute;
    top: 10px;
    left: 0;
    content: '';
    display: block;
    width: 1em;
    height: 1em;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMTQuNDY5IDE0LjQ2OSIgYXJpYS1oaWRkZW49InRydWUiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAyOTg5MiIgZD0iTTMuNjIxLjc1djEuNDU1aDcuNjE0TDEuMDYxIDEyLjM4bDEuMDI4IDEuMDI4TDEyLjI2MSAzLjIzNHY3LjkxNmgxLjQ1NFYuNzVaIiBmaWxsPSIjYWU3ZDJjIiBzdHJva2U9IiNhZTdkMmMiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+');
    background-size: cover; /* Or set a specific size */
    background-repeat: no-repeat;
}

.subtitle,
.icon-list-title {
    font-size: 1em;
    color: var(--color-primary-light);
    font-weight: 700;
    text-transform: uppercase;
}

.icon-list-title {
    display: block;
    clear: both;
    text-transform: none;
}

.flex-hack-purpose .subtitle {
    color: #fff;
}

.mask {
    opacity: .50;
}

.video-title {
    margin: 0;
    padding: 20px 0 30px;
}

@media (min-width: 80em) {
    .video-title {
        margin: 0;
        padding: 100px 0 50px;
    }

    .my-results.career > div:first-child {
        grid-column: 1/2;
        text-align: left;
    }

    .careers-video .block-element-custom {
        height: 445px;
        width: 35%;
    }

    .careers-video .watermark-custom {
        left: -190px;
        &::after {
            top: -50px;
        }
    }
}

.careers-video video {
    border: 1px solid #000;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

video[poster]{
    height:auto;
    width:100%;
    object-fit: cover;
}
/*----------------*/
/*  Backgrounds   */
/*----------------*/
 %bg-dark {
	background: var(--color-primary);
	color: var(--color-white);

	a {
		color: var(--color-white);
	}
}

.bg-gradient {
	background: var(--color-primary);
	background: linear-gradient(225deg, var(--color-primary-darkest) 0%, var(--color-primary) 85%, var(--color-primary) 100%);
}

.bg-gradient-reverse {
    background: var(--color-primary);
    background: linear-gradient(25deg, var(--color-primary-darkest) 30%, var(--color-primary) 65%, var(--color-primary-light) 100%);
}

.bg-gradient-alt {
	background: var(--color-primary);
	background: linear-gradient(45deg, var(--color-primary-darkest) 0%, var(--color-primary) 85%, var(--color-primary) 100%);
}

.bg-gradient-alt-light {
    background: var(--color-primary);
    background: linear-gradient(45deg, var(--color-primary-darkest) 25%, var(--color-primary) 55%, var(--color-primary-light) 100%);
}

.bg-gradient-alt-light-reverse {
	background: var(--color-primary);
	background: linear-gradient(225deg, var(--color-primary-darkest) 0%, var(--color-primary-light) 55%, var(--color-primary-lightest) 100%);
}

.bg-gradient-vertical {
	background: var(--color-primary);
	background: linear-gradient(10deg, var(--color-primary-darkest) 0%, var(--color-primary-darkest) 30%, var(--color-primary-middle) 60%, var(--color-primary-light) 100%);
}

.bg-gradient-vertical-alt {
	background: var(--color-primary);
	background: linear-gradient(10deg, var(--color-primary-darkest) 0%, var(--color-primary-light) 85%, var(--color-primary-light) 100%);
}

.bg-gradient-header {
	background: var(--color-primary-darkest);
	background: linear-gradient(7deg, var(--color-primary-darkest) 0%, var(--color-primary-darkest) 65%, var(--color-primary) 100%);
}

.bg-muted {
	background-color: var(--color-gray-lighter);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-black {
	@extend %bg-dark;
	background-color: var(--color-primary-darkest);
}

.bg-primary {
	@extend %bg-dark;
	background-color: var(--color-primary-light);
}

.bg-accent {
	@extend %bg-dark;
	background-color: var(--color-accent);
}

.bg-semi-transparent {
	background-color: rgba(0, 0, 0, 0.2);
}

.bg-semi-transparent-dark {
	backdrop-filter: blur(2em);
	background-color: rgba(0, 0, 0, 0.4);
}

.bg-semi-transparent-light {
	backdrop-filter: blur(2em);
	background-color: rgba(214, 211, 201, 0.2);
}

/**
 * @section Typography
 * Sets font styles for entire site
 */

html {
	scroll-behavior: smooth;
}

body {
	background: var(--color-white);
	color: var(--color-black);
	font-family: var(--font-primary);
	font-size: var(--font-size);
	line-height: 1.5;
	margin: 0 auto;
	max-width: var(--body-max-width);
	overflow-x: hidden;

	@media (min-width: $bp-medium) {
		line-height: 1.5625;
	}
}

p {
	margin: 0 0 var(--spacing);
}


/**
 * Hyperlink styling
 */

a {
	color: var(--color-primary);
	text-decoration-skip-ink: auto;
	word-wrap: break-word;

	&:active,
	&:focus,
	&:hover {
		color: var(--color-primary-dark);
	}
}

.link-accent {
	color: var(--color-accent);

	&:active,
	&:focus,
	&:hover {
		color: var(--color-accent-dark);
	}
}

.link-no-underline {
	text-decoration: none;

	&:active,
	&:focus,
	&:hover {
		text-decoration: underline;
	}
}


/**
 * List styling
 */

ul,
ol {
	margin: 0 0 var(--spacing) 2em;
	padding: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
	margin-bottom: 0;
}

dl,
dd {
	margin: 0;
	padding: 0;
}

dd {
	margin-bottom: var(--spacing);
}

dt {
	font-weight: bold;
}

/**
 * @bugfix Prevent webkit from removing list semantics
 * 1. Add a non-breaking space
 * 2. Make sure it doesn't mess up the DOM flow
 */
%list-style-none {
	list-style: none;
	margin-left: 0;

	& > li::before {
		content: "\200B"; /* 1 */
		position: absolute; /* 2 */
	}
}

/**
 * Removes list styling.
 * For semantic reasons, should only be used on unordered lists.
 */
.list-unstyled {
	@extend %list-style-none;
	margin-left: 2em;
}

.list-icons {
	@extend %list-style-none;
	margin-left: 0;
}

.list-item-icon {
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	column-gap: 1em;

	span:first-child {
		padding-top: 0.25em;
	}
}

/**
 * Display lists on a single line.
 */

.list-inline {
	@extend %list-style-none;
	display: flex;
	column-gap: 1em;

	@media (max-width: $bp-medium) {
		&.list-inline-responsive {
			display: block;
		}
	}

	// @extend %list-style-none;
	// margin-left: -0.5em;
	// margin-right: -0.5em;
	// padding: 0;

	// & > li {
	// 	display: inline-block;
	// 	margin-left: 0.5em;
	// 	margin-right: 0.5em;
	// }

	// @media (max-width: $bp-medium) {
	// 	&.list-inline-responsive > li {
	// 		display: block;
	// 	}
	// }
}

.list-inline-wrap {
	flex-wrap: wrap;
}

.list-inline-center {
	justify-content: center;
}

@media (min-width: $bp-large) {
	.list-inline-right-large {
		justify-content: end;
	}
}

.list-inline-spaced {
	column-gap: 2em;
	// margin-left: -1em;
	// margin-right: -1em;

	// & > li {
	// 	margin-left: 1em;
	// 	margin-right: 1em;
	// }
}

.list-inline-divided {

	& > li:not(:first-child) {
		border-left: 1px solid currentColor;
		padding-left: 1.25em;
	}

	@media (max-width: $bp-medium) {
		&.list-inline-responsive > li {
			border-left: none;
			// display: block;
			padding-left: 0;
		}
	}
}

.list-split {
	@extend .list-inline;
	justify-content: space-between;
}

.list-breadcrumbs {
	@extend .list-inline;
	@extend .list-inline-wrap;
	column-gap: 0;
	margin-left: 2em;
	padding-top: 1em;

	& > li {
		font-size: 0.8125em;

		& > a {
			color: var(--color-black);
			font-weight: bold;
			text-decoration: none;

			&[aria-current="page"] {
				font-weight: normal;
			}

			&:active,
			&:hover,
			&:focus {
				color: var(--color-primary);
				text-decoration: underline;
			}
		}
	}

	& > li:not(:last-child)::after {
		content: ">";
		margin-left: 0.25em;
		margin-right: 0.25em;
	}

	@media (max-width: $bp-medium) {
		margin-left: 0;
	}
}

.list-spaced li {
	margin-bottom: 1em;
}

.list-spaced-large li {
	margin-bottom: 1.2em;
}


/**
 * Heading styling for h1 through h6 elements.
 * Heading class lets you use one heading type for semantics, but style it as another heading type.
 */

h1, h2, h3, h4, h5, h6 {
	font-weight: 900;
	line-height: 1.2;
	margin: 0 0 1em;
	padding: 1em 0 0;
	word-wrap: break-word;
}

h1,
.h1 {
	font-size: 2em;
	padding-top: .5em;

	@media (min-width: $bp-xlarge) {
		font-size: 2.75em;
	}
}

.h1-hero {
	font-size: 2.5em;

	@media (min-width: $bp-xlarge) {
		font-size: 3.5em;
	}
}

h2,
.h2 {
	font-size: 1.75em;

	@media (min-width: $bp-xlarge) {
		font-size: 2em;
	}
}

h3,
.h3 {
	font-size: 1.5em;

	@media (min-width: $bp-xlarge) {
		font-size: 1.7em;
	}
}

h4, h5, h6,
.h4, .h5, .h6 {
	font-size: 1em;
}

h4,
.h4 {
	font-size: 0.8125em;
	text-transform: uppercase;
}


/**
 * Lines, Quotes and Emphasis
 */

 .small {
    font-size: 0.625em;
    text-transform: uppercase;
 }

 .custom-h2 {
    padding-top: 0;
 }

/**
 * Lines
 */
hr {
	border: 0;
	border-top: 1px solid var(--color-hr-line);
	margin: 2em auto;
}

.line-accent-color {
	border-top-color: var(--color-accent);
}

.line-dark {
	border-top-color: var(--color-gray-dark);
}

.line-accent {
	border-top: 0.25em solid var(--color-accent);
	width: 4em;
	margin: 1.5em auto;
}

.line-accent-left {
	margin: 1.5em auto 1.5em 0;
}

.line-accent-full {
	width: 100%;
}

.line-accent-primary {
	border-top-color: var(--color-primary-light);
}

.line-accent-light {
	border-top-color: var(--color-primary-lightest);
}

.line-compact {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.line-overlay {
	margin-top: -2.8em;

	& > * {
		background-color: var(--color-white);
		display: inline-block;
		padding: 0 0.25em;

		// @media (min-width: $bp-medium) {
		// 	padding: 0 2em;
		// }
	}
}

/**
 * Use a lighter yellow for better readability
 */
mark {
	background: transparent;
	color: var(--color-black);
	text-decoration: underline;
}


/**
 * Blockquotes
 */

blockquote {
	color: var(--color-primary-light);
	font-size: 1.35em;
	font-style: italic;
	font-weight: bold;
	line-height: 1.4;
	margin: 0 0 var(--spacing);
	padding-left: 0.8125em;
	padding-right: 0.8125em;
	text-align: center;
}

cite {
	color: var(--color-primary);
	display: block;
	font-size: 0.625em;
	font-style: normal;
	padding-top: 0.5em;
}

.color-accent {
	color: var(--color-accent);
}

.color-accent-dark {
	color: var(--color-accent-dark);
}

.color-primary {
	color: var(--color-primary);
}	

.color-primary-dark {
	color: var(--color-primary-dark);
}

.color-primary-light {
	color: var(--color-primary-light);
}


.text-bg-dark {
	color: var(--color-white);

	a {
		color: var(--color-white);
	}
}